import { render } from 'storyblok-rich-text-react-renderer';
import { flatten, isString } from './nodash';
import richTextResolvers from './richTextResolvers';

const renderRichTextToText = (body) => {
  const rendered = render(body, richTextResolvers);
  const text = flatten(
    rendered.map((x) =>
      flatten(
        (x.props?.children || []).filter((z) => {
          return isString(z);
        })
      ).join(' ')
    )
  ).join(' ');
  return text;
};

export default renderRichTextToText;
