import React, { useEffect, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { graphql } from 'gatsby';
import { FAQJsonLd } from 'gatsby-plugin-next-seo';
import { Box } from 'grommet';
import queryString from 'query-string';

import { useStoryblokState } from '../lib/storyblok';
import { get, some, flatten } from '../lib/nodash';
import renderRichTextToText from '../lib/renderRichTextToText';
import { enableFilter, disableFilter } from '../state/ui/uiSlice';
import usePageBodyComponents from '../components/DynamicPage/usePageBodyComponents';
import Seo from '../components/Seo';
import useMenuOffset from '../components/useMenuOffset';
import selectActivePlacementId from '../state/gallery/selectActivePlacementId';

const StoryblokPageTemplate = ({
  data: { storyblokEntry, site },
  location,
}) => {
  const isFilteredPaintCollection =
    location.pathname.includes('/collections/paint') &&
    location.search.includes('filter=');
  const colorFilters = (queryString.parse(location.search).filter || '').split(
    ','
  );
  const canonicalUrl =
    site.siteMetadata.siteUrl +
    (isFilteredPaintCollection
      ? `/collections/paint/${
          colorFilters.length > 1 ? '' : `${colorFilters[0]}-paint/`
        }`
      : location.pathname);
  const dispatch = useDispatch();
  const story = useStoryblokState(storyblokEntry, location);
  const pageTitle =
    get('content.metafields.title', story) ||
    get('title', story) ||
    get('field_title_string', storyblokEntry);
  const offset = get('content.offsetForMenu', story);
  const activePlacementId = useSelector(selectActivePlacementId);
  const components = usePageBodyComponents(story, {
    activePlacementId,
    pageTitle,
    pageSlug: story.full_slug,
  });
  const { navHeight } = useMenuOffset();
  const isFaq = storyblokEntry.full_slug === 'pages/faq';
  const allQuestions = isFaq
    ? flatten(
        (components || []).map((x) => {
          return (x.props?.blok?.items || []).filter(
            (z) => z.component === 'CollapsibleText'
          );
        })
      )
    : [];

  const qaMap = useMemo(
    () =>
      allQuestions.map((q) => ({
        question: q.title,
        answer: renderRichTextToText(q.body),
      })),
    [allQuestions, renderRichTextToText]
  );

  useEffect(() => {
    if (some({ component: 'PaintBrowser' }, get('content.body', story))) {
      dispatch(enableFilter());
    } else {
      dispatch(disableFilter());
    }
  }, [story]);

  return (
    <>
      <Seo
        title={pageTitle}
        canonical={canonicalUrl}
        description={get('content.metafields.description', story)}
        image={get('content.seoImage.filename', story)}
        noIndex={get('content.noIndex', story)}
      />
      {isFaq && <FAQJsonLd questions={qaMap} />}
      <Box pad={{ top: offset ? `${navHeight}px` : 0 }}>{components}</Box>
    </>
  );
};

StoryblokPageTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default memo(StoryblokPageTemplate);

export const query = graphql`
  query StoryblokPageContent($full_slug: String!) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
      id
      name
      full_slug
      content
      field_title_string
      internalId
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
