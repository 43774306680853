import { createSelector } from 'reselect';

const selectActivePlacementId = createSelector(
  (state) => state.gallery,
  (gallery) => {
    return gallery.activePlacementId;
  }
);

export default selectActivePlacementId;
